<template>
  <vs-button
    v-if="params.value"
    color="primary"
    type="filled"
    size="small"
    icon-pack="feather"
    icon="icon-external-link"
    target
    :href="params.value">
    {{ $t('$General.View') }}
  </vs-button>
</template>

<script>
/**
 * Cell renderer receipt to billing history transactions
 *
 * @module views/modules/user-account/UserAccountBillingHistoryCellRendererReceipt
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'UserAccountBillingHistoryCellRendererStatus',
};
</script>
