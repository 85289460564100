<template>
  <span class="billing-history-amount">
    {{ amount | dollar }} {{ currency | uppercase }}
  </span>
</template>

<script>
/**
 * Cell renderer amount to billing history transactions
 *
 * @module views/modules/user-account/UserAccountBillingHistoryCellRendererAmount
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'UserAccountBillingHistoryCellRendererStatus',
  computed: {
    value() {
      return this.params.value || 0;
    },
    amount() {
      return this.value
        ? this.value / 100
        : '';
    },
    currency() {
      return this.params && this.params.node.data
        ? this.params.node.data.currency
        : '';
    },
  },
};
</script>
