<template>
    <vs-chip
      class="ag-grid-cell-chip billing-history-status"
      :color="chipColor(status)">
      <div class="billing-status-icon"><feather-icon
        :icon="chipIcon(status)"
        svgClasses="h-5 w-5 ml-1" /></div>
      <div class="billing-status-text">
        {{ chipStatus }}
      </div>
    </vs-chip>
</template>

<script>
/**
 * Cell renderer status to billing history transactions
 *
 * @module views/modules/user-account/UserAccountBillingHistoryCellRendererStatus.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {string} chipColor - color of chip
 * @vue-computed {string} chipColor - icon of chip
 */
export default {
  name: 'UserAccountBillingHistoryCellRendererStatus',
  computed: {
    status() {
      return this.params.value || '';
    },
    chipStatus() {
      return this.status
        ? this.$t(`$Stripe.$Invoice.$Status.${this.status}`)
        : '';
    },
    chipColor() {
      return (value) => {
        switch (value) {
          case this.$enums.StripeEnums.Invoice.Status.DRAFT:
            return 'grey';
          case this.$enums.StripeEnums.Invoice.Status.OPEN:
            return 'warning';
          case this.$enums.StripeEnums.Invoice.Status.UNCOLLECTIBLE:
          case this.$enums.StripeEnums.Invoice.Status.VOID:
            return 'danger';
          case this.$enums.StripeEnums.Invoice.Status.PAID:
            return 'success';

          default:
            return '';
        }
      };
    },
    chipIcon() {
      return (value) => {
        switch (value) {
          case this.$enums.StripeEnums.Invoice.Status.DRAFT:
            return 'SendIcon';
          case this.$enums.StripeEnums.Invoice.Status.OPEN:
            return 'ClockIcon';
          case this.$enums.StripeEnums.Invoice.Status.PAID:
            return 'CheckIcon';
          case this.$enums.StripeEnums.Invoice.Status.UNCOLLECTIBLE:
            return 'AlertCircleIcon';
          case this.$enums.StripeEnums.Invoice.Status.VOID:
            return 'XIcon';

          default:
            return '';
        }
      };
    },
  },
};
</script>
