<template>
  <div class="vx-card p-6">
    <base-ag-grid-header
      :rows-selected-count="0"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">

      <template v-slot:header-right>
        <vs-button
          radius
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-refresh-cw"
          class="mr-3"
          @click="resetDataSourceAndSelection()">
        </vs-button>
      </template>
    </base-ag-grid-header>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="billing-story-table ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      :suppressColumnVirtualisation="suppressColumnVirtualisation"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import tenantService from '@/api/modules/tenant.service';

// Mixins
import commonAgGrid from '@/views/modules/_mixins/commonAgGrid';

// Custom components
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import cellRendererStatus from '@/views/modules/user/user-billing/user-billing-history/UserAccountBillingHistoryCellRendererStatus.vue';
import cellRendererReceipt from '@/views/modules/user/user-billing/user-billing-history/UserAccountBillingHistoryCellRendererReceipt.vue';
import cellRendererAmount from '@/views/modules/user/user-billing/user-billing-history/UserAccountBillingHistoryCellRendererAmount.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';

/**
 * show user billing history
 *
 * @module views/modules/user-account/UserAccountBillingHistory
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [paginationNextPage=null] -
 * indicate if the current page is a next or preview page
 * @vue-data {string} endingBefore - id of first item of current page to use in pagination
 * @vue-data {string} startingAfter - id of last item of current page to use in pagination
 * @vue-data {Object[]} commonColumnDefs - common columns definition
 * @vue-data {Object[]} columnDefs - columns definition
 * @vue-data {Object} components - ag grid components
 * @vue-data {Object} frameworkComponents - ag grid framework components
 * @vue-event {void} created - created hook to set columns configurations
 * @vue-event {void} currentPage - watch to set paginationNextPage
 * @vue-event {Object[] | any} fetchAgGridData - fetch data to show
 */
export default {
  name: 'UserAccountBillingHistory',
  components: {
    ItemsPerPage,
    BaseAgGridHeader,
    // eslint-disable-next-line vue/no-unused-components
    cellRendererStatus,
    // eslint-disable-next-line vue/no-unused-components
    cellRendererReceipt,
    // eslint-disable-next-line vue/no-unused-components
    cellRendererAmount,
  },
  mixins: [commonAgGrid],
  data() {
    return {
      paginationNextPage: null,
      showOnlyFirstColumnOnMobile: false,
      currentIndexPagesNextArrays: 0,
      pagesNextArrays: [null],
      commonColumnDefs: [],
      columnDefs: [
        {
          colId: 'status',
          headerName: this.$t('$General.Status'),
          field: 'status',
          filter: false,
          minWidth: 160,
          maxWidth: 260,
          headerClass: 'justify-center',
          cellRendererFramework: 'cellRendererStatus',
        },
        {
          colId: 'amount',
          headerName: this.$t('$General.Amount'),
          field: 'total',
          filter: false,
          minWidth: 70,
          maxWidth: 350,
          headerClass: 'justify-center',
          cellClass: 'text-center',
          cellRendererFramework: 'cellRendererAmount',
        },
        {
          colId: 'date',
          headerName: this.$t('$General.Date'),
          field: 'date',
          sort: 'desc',
          type: 'dateColumn',
          headerClass: 'justify-center',
          cellClass: 'text-center',
        },
        {
          colId: 'number',
          headerName: 'Invoice number',
          field: 'number',
          filter: false,
          minWidth: 100,
          maxWidth: 300,
          headerClass: 'justify-center',
          cellClass: 'text-center',
        },
        {
          colId: 'hosted_invoice_url',
          headerName: 'Invoice',
          field: 'hosted_invoice_url',
          filter: false,
          minWidth: 100,
          maxWidth: 220,
          headerClass: 'justify-center',
          cellClass: 'flex items-center justify-center',
          cellRendererFramework: 'cellRendererReceipt',
        },
      ],
      components: {
        cellRendererStatus,
        cellRendererReceipt,
        cellRendererAmount,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  created() {
    window.history.replaceState({}, null, '/billing/history');
    this.defaultColDef.sortable = false;
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.paginationNextPage = newVal > oldVal;
    },
  },
  methods: {
    async fetchAgGridData(params) {
      const reqParams = params;

      if (this.paginationNextPage !== null) {
        if (this.paginationNextPage) this.currentIndexPagesNextArrays += 1;
        else if (!this.paginationNextPage) this.currentIndexPagesNextArrays -= 1;

        reqParams.nextPage = this.pagesNextArrays[this.currentIndexPagesNextArrays];
      }

      const resp = await tenantService.getBillingHistory(reqParams);

      if (resp.data.nextPage) {
        this.pagesNextArrays.push(resp.data.nextPage);
      }

      return resp.data;
    },
  },
};
</script>

<style lang="scss">
.billing-story-table {
  height: auto !important;
  .ag-root-wrapper-body {
    height: auto;
  }
  .ag-header-viewport {
    .ag-header-cell {
    }
  }

  .ag-body-viewport {
    .ag-center-cols-clipper {
      height: auto !important;
      min-height: 0 !important;

      .ag-center-cols-container {
        height: auto !important;
        min-height: 0 !important;
        max-height: none !important;

        .ag-row:nth-last-child(1) {
          border-bottom: 0px !important;
        }

        .ag-row {
          height: auto !important;
          position: relative !important;
          transform: none !important;
          display: flex;
          height: 65px !important;

          .ag-cell {
            position: relative !important;
            border: 0px;
            left: 0px !important;
            line-height: 65px !important;

            .billing-history-amount {
              font-weight: 600;
            }

            .billing-history-status {
              background: transparent !important;
              font-size: 14px;
              line-height: 1.5;
              letter-spacing: 0.01rem;
              font-weight: 600;
              float: left;
              padding-left: 60px;

              .feather-icon {
                border-radius: 1000px;
                padding: 5px;
                margin-right: 6px;

                svg {
                  stroke: #ffffff;
                  margin-left: 0 !important;
                }
              }

              .billing-status-text {
              }
            }

            .billing-history-status.vs-chip-success .feather-icon {
              background-color: rgba(var(--vs-success), 1) !important;
            }

            .billing-history-status.vs-chip-warning .feather-icon {
              background-color: rgba(var(--vs-warning), 1) !important;
            }

            .billing-history-status.vs-chip-grey .feather-icon {
              background-color: rgba(var(--vs-grey), 1) !important;
            }

            .billing-history-status.vs-chip-danger .feather-icon {
              background-color: rgba(var(--vs-danger), 1) !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .billing-story-table {
    .ag-body-viewport {
      .ag-center-cols-clipper {
        .ag-center-cols-viewport {
          overflow-x: hidden;
        }
        .ag-center-cols-container {
          .ag-row {
            .ag-cell {
              .billing-history-status {
                padding-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .billing-story-table {
    .ag-header-container {
      .ag-header-cell:not(:nth-child(1)) {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: -40px;
      }

      .ag-header-cell:nth-child(1) {
        padding-left: 30px !important;
        padding-right: 0px !important;
      }
    }

    .ag-body-viewport {
      .ag-center-cols-clipper {
        .ag-center-cols-container {
          .ag-row {
            .ag-cell:not(:nth-child(1)) {
              padding-left: 0px !important;
              padding-right: 0px !important;
              left: -40px !important;
            }

            .ag-cell:nth-child(1) {
              padding-left: 10px !important;
              padding-right: 0px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .billing-story-table {
    .ag-header {
      display: none;
    }

    .ag-body-viewport {
      .ag-center-cols-clipper {
        .ag-center-cols-container {
          .ag-row {
            height: 130px !important;

            .ag-cell {
              position: absolute !important;
              line-height: 18px !important;
              width: 200px !important;
            }

            .ag-cell:nth-child(1) {
              left: -50px !important;
            }

            .ag-cell:nth-child(2) {
              left: 25% !important;
              top: 30px;
            }

            .ag-cell:nth-child(4) {
              left: 66% !important;
              top: 30px;
            }

            .ag-cell:nth-child(3) {
              left: 25% !important;
              top: 80px;
            }

            .ag-cell:nth-child(5) {
              left: 66% !important;
              top: 20px;
            }

            .billing-history-status {
              text-align: center;
              width: 100%;
              .text-chip {
                display: block;
              }
              .feather-icon {
                margin-right: 0px !important;
              }
            }
          }

          .ag-row:nth-child(1) {
            border-top: 1px solid
              var(
                --ag-row-border-color,
                var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
              );
          }

          .ag-row:nth-last-child(1) {
            border-bottom: 1px solid
              var(
                --ag-row-border-color,
                var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
              ) !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 630px) {
  .billing-story-table {
    .ag-body-viewport {
      .ag-center-cols-clipper {
        .ag-center-cols-container {
          .ag-row {
            .ag-cell:nth-child(2) {
              left: 20% !important;
            }

            .ag-cell:nth-child(4) {
              left: 50% !important;
            }

            .ag-cell:nth-child(3) {
              left: 20% !important;
            }

            .ag-cell:nth-child(5) {
              left: 50% !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .billing-story-table {
    .ag-body-viewport {
      .ag-center-cols-clipper {
        .ag-center-cols-viewport {
        }
        .ag-center-cols-container {
          width: auto !important;
          .ag-row {
            height: 220px !important;
            .ag-cell {
              width: 100% !important;
              height: auto;
            }

            .ag-cell:nth-child(1) {
              height: 55px;
              left: auto !important;
              top: 15px !important;
              padding-left: 0px !important;
              .billing-history-status {
                padding-left: 0px !important;
              }
            }

            .ag-cell:nth-child(2) {
              left: auto !important;
              top: 75px !important;
            }

            .ag-cell:nth-child(3) {
              left: auto !important;
              top: 105px !important;
            }

            .ag-cell:nth-child(4) {
              left: auto !important;
              top: 135px !important;
            }

            .ag-cell:nth-child(5) {
              left: auto !important;
              top: 165px !important;
            }
          }
        }
      }
    }
  }
}
</style>
